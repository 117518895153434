import React from "react";

export const BlouppyWithTextIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="96.858 168.766 327.592 114.91"
    {...props}
  >
    <g transform="translate(153.64 226.22)">
      <linearGradient
        id="SVGID_7"
        x1="46.533"
        x2="160.082"
        y1="84.171"
        y2="84.171"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#FFC1A3"></stop>
        <stop offset="1" stopColor="#FFB1BB"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_7)"
        d="M153.7 114.1c-2.5 4.4-5.9 8.6-10 12.4-9.2 8.5-21.1 13.5-33.5 14.8-24.8 2.6-48.2-9.8-44.5-40.2 1.2-9.7 5-18.9 10.7-26.9 1.8-2.6 4-5.2 6.4-7.2.8-.7 2-.7 2.8 0 .5.4.8 1 .8 1.6 0 .3-.1.6-.2 1-14.4 29.3-4.5 51.4 11.5 56.8 16.2 5.4 46-8.6 45.6-32.7.2-7.3-5.9-13.8-13.9-14.9-6.9-.9-15.3 2.1-22.2 12.1-5.4-7.6-3.8-18.7.1-25.7 2.2-4 4.6-7.6 5.9-11 1.4-3.7 1.4-7.1-1.6-10.3-5-5.3-23.4-7.4-42.1 10.3-6.2 5.8-11.1 12.7-14.8 20.3-7.8 16.2-5.8 26.1-5.6 27-10.7-35.6 13.3-67.7 44.6-73.8h.1c4.8-.9 9.8-1.2 14.8-.8 5 .4 9.8 1.8 14 4.4C142.7 43.6 126 65 126 65c30.3-7.5 42.7 23.5 27.7 49.1z"
        transform="translate(-103.29 -84.18)"
        vectorEffect="non-scaling-stroke"
      ></path>
    </g>
    <g transform="translate(132.25 206.46)">
      <linearGradient
        id="SVGID_8"
        x1="46.532"
        x2="117.315"
        y1="64.426"
        y2="64.426"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#EFC2EB"></stop>
        <stop offset="1" stopColor="#AFC1EE"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_8)"
        d="M113.1 54.2c1.4-3.7 1.4-7.1-1.6-10.3-5-5.3-23.4-7.4-42.1 10.3-6.1 5.8-11 12.7-14.7 20.3-8 16.6-5.7 26.6-5.6 27.1-10.7-35.7 13.2-67.8 44.6-73.9h.2c21.2-3 29 8.9 19.2 26.5z"
        transform="translate(-81.9 -64.42)"
        vectorEffect="non-scaling-stroke"
      ></path>
    </g>
    <g transform="translate(160.69 246.09)">
      <linearGradient
        id="SVGID_9"
        x1="65.304"
        x2="155.338"
        y1="104.057"
        y2="104.057"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#AFC1EE"></stop>
        <stop offset="1" stopColor="#EFC2EB"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_9)"
        d="M153.8 114.1c-.1 0-.1 0 0 0-2.6 4.4-5.9 8.6-10.1 12.4-9.2 8.5-21.1 13.5-33.5 14.8-24.8 2.6-48.2-9.8-44.5-40.2 1.2-9.7 5-18.9 10.7-26.9 1.8-2.6 4-5.2 6.4-7.2.8-.7 2-.7 2.8 0 .5.4.8 1 .8 1.6 0 .3-.1.6-.2 1-14.4 29.3-4.5 51.4 11.5 56.8 16.2 5.4 46-8.6 45.6-32.7.2-7.3-5.9-13.8-13.9-14.9 14.5-.8 31.2 16.6 24.4 35.3z"
        transform="translate(-110.34 -104.06)"
        vectorEffect="non-scaling-stroke"
      ></path>
    </g>
    <g transform="translate(137.65 205.99)">
      <linearGradient
        id="SVGID_10"
        x1="84.312"
        x2="90.188"
        y1="63.867"
        y2="63.867"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#EFC2EB"></stop>
        <stop offset="1" stopColor="#AFC1EE"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_10)"
        d="M90.2 65.4l-.8.7c-.4.3-.9.4-1.3.2-.6-.2-1.2-.6-1.7-.9-.1-.1-.1-.1-.2-.1s-.1-.1-.2-.1c-.2-.2-.4-.3-.6-.5-.2-.3-.5-.5-.7-.8l-.1-.1c-.1-.2-.2-.4-.2-.7 0-.2 0-.4.1-.6l.3-.6.1-.2v-.2l1.7 1.3.1.1.3.2.5.4 1 .7 1.3 1 .1.1.3.1z"
        transform="translate(-87.3 -63.95)"
        vectorEffect="non-scaling-stroke"
      ></path>
    </g>
    <path
      fill="#FFF"
      d="M85.4 61.8l-.4 1c-.1.2-.2.5-.1.8 0 .3.1.5.3.7.1.2.3.3.4.5-.3-.3-.6-.6-.8-.9-.1-.2-.2-.4-.3-.7 0-.2 0-.5.1-.7l.5-1 .3.3z"
      opacity="0.6"
      transform="translate(135.4 205.19) translate(-85.05 -63.15)"
      vectorEffect="non-scaling-stroke"
    ></path>
    <path
      fill="#390075"
      d="M88.4 64l-1-.7-.4-.4-.3-.2-.1-.1-1.7-1.3v.1l.1.1c0 .1.1.1.1.1l.3.3 1.1.9.1.1.3.2.5.4.6.5h-.1l.3.3.1.1-.3.2h-.1l-.3.4c-.3.3-.7.4-1.1.3h-.1.1l1.2.6c.4.2 1 .1 1.3-.2l.6-.5.2-.1-1.4-1.1z"
      opacity="0.35"
      transform="translate(137.7 205.69) translate(-87.35 -63.65)"
      vectorEffect="non-scaling-stroke"
    ></path>
    <path
      fill="#EC1C24"
      d="M86.3 58.6l-.7 2.9c-.2-.2-.5-.3-.8-.4h-.1-.1c-1.1-.2-2.5.3-3.3.5-.2.1-.3.1-.4.1-.1 0-.1 0-.1-.1 1.5-3 3.2-3.6 4.4-3.4.5.1.8.2 1.1.4-.1-.1 0 0 0 0z"
      transform="translate(133.9 201.97) translate(-83.55 -59.93)"
      vectorEffect="non-scaling-stroke"
    ></path>
    <path
      fill="#FFF"
      d="M86.2 58.5c-1.3-.3-3.2.2-4.9 3.1-.2.1-.3.1-.4.1-.1 0-.1 0-.1-.1 2-3.9 4.4-3.7 5.4-3.1z"
      opacity="0.6"
      transform="translate(133.85 201.97) translate(-83.5 -59.93)"
      vectorEffect="non-scaling-stroke"
    ></path>
    <path
      fill="#D25658"
      d="M92.4 63.2c.9.8 1.8 3.1-1.5 6.1 0 0-.1 0-.1-.1.1-.6.3-3.6-1-4.6l2.6-1.4z"
      transform="translate(141.87 208.29) translate(-91.52 -66.25)"
      vectorEffect="non-scaling-stroke"
    ></path>
    <path
      fill="#EC1C24"
      d="M90.9 69.3s-.1 0-.1-.1c0-.3.1-1.3 0-2.2-.1-.9-.4-1.9-1-2.3l2.6-1.4c.3.2.5.6.7 1 .4 1.1.2 2.8-2.2 5z"
      transform="translate(141.88 208.34) translate(-91.53 -66.3)"
      vectorEffect="non-scaling-stroke"
    ></path>
    <path
      fill="#390075"
      d="M90.8 67c-.1-.9-.4-1.9-1-2.3l2.6-1.4c.3.2.5.6.7 1-.3.8-1 1.8-2.3 2.7z"
      opacity="0.35"
      transform="translate(141.8 207.19) translate(-91.45 -65.15)"
      vectorEffect="non-scaling-stroke"
    ></path>
    <path
      fill="#FFF"
      d="M101.7 52.6c-.5 1.6-1.2 3.5-2.5 5.4-2.1 3.3-4.5 5.1-6.2 6.2-1.2.7-2.2 1.1-2.6 1.2-.1 0-.2.1-.2.1-.2-.1-.4-.1-.6-.2-.2-.1-.4-.1-.6-.2h-.1c-.3-.1-.5-.3-.8-.4l-.1-.1-.9-.6c-.1-.1-.3-.2-.4-.3-.1-.1-.2-.1-.3-.2-.1 0-.1-.1-.1-.1-.4-.4-.7-.7-1-1.1-.1-.1-.2-.3-.3-.4l-.1-.1-.1-.1v-.3-.3c0-.5.1-1.3.4-2.3 0-.1 0-.2.1-.3.2-.7.4-1.5.8-2.3.1-.1.1-.2.2-.3.6-1.2 1.4-2.6 2.5-4 .3-.3.5-.7.8-1 1.2-1.4 2.4-2.4 3.6-3.2l.1-.1c.1-.1.2-.2.3-.2.1-.1.3-.2.4-.3.1-.1.3-.2.4-.3.2-.1.3-.2.5-.3.3-.1.6-.3.8-.4.1-.1.3-.1.4-.2.2-.1.4-.2.6-.2.2-.1.4-.1.5-.2 1.6-.5 2.8-.6 3.6-.6h.8s.1.1.1.3c.5.7 1.1 3.5 0 7.4z"
      transform="translate(143.91 197.24) translate(-93.56 -55.2)"
      vectorEffect="non-scaling-stroke"
    ></path>
    <path
      fill="#EC1C24"
      d="M101.8 52.6c-.1-.1-.2-.3-.3-.4-.3-.4-.7-.8-1.1-1.1-1.7-1.6-3.6-3-5.8-4.1-.2-.1-.3-.2-.5-.3.1-.1.3-.2.4-.3.2-.1.3-.2.5-.3.3-.1.6-.3.8-.4.1-.1.3-.1.4-.2.2-.1.4-.2.6-.2.2-.1.4-.1.5-.2 1.7-.6 3.1-.7 3.8-.7h.7s.1.1.1.4c.3 1.2 1 4-.1 7.8z"
      transform="translate(148.6 190.54) translate(-98.25 -48.5)"
      vectorEffect="non-scaling-stroke"
    ></path>
    <g transform="translate(143.8 197.04)">
      <linearGradient
        id="SVGID_11"
        x1="84.719"
        x2="102.108"
        y1="54.862"
        y2="54.862"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#FFC1A3"></stop>
        <stop offset="1" stopColor="#FFB1BB"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_11)"
        d="M101.4 52.2c-.5 1.6-1.3 3.4-2.5 5.3-2.1 3.2-4.3 5.1-6.1 6.2-1.1.7-2.1 1.1-2.6 1.3-.1 0-.2.1-.3.1-.2.1-.3.1-.3.1-.2-.1-.4-.1-.6-.2h-.1c-.3-.1-.6-.2-.8-.4-.1 0-.1-.1-.2-.1-.3-.2-.6-.3-.9-.5-.1-.1-.3-.2-.4-.3-.1-.1-.2-.1-.3-.2-.1 0-.1-.1-.1-.1-.1-.1-.2-.1-.3-.2-.3-.3-.6-.6-.8-.9-.1-.1-.2-.3-.3-.5l-.1-.1.1-.2V61.2l.1.1c.5.5 1.1.8 1.8 1.1.1 0 .1.1.2.1.1.1.2.1.4.1.2.1.5.1.7.2.4.1.8.1 1.2.1 1 0 2-.4 2.9-.9 1.6-1.1 3.7-2.8 5.6-5.6-1.2 1-3 1.1-4.3.1-.9-.7-1.4-1.7-1.4-2.8 0-.7.3-1.5.7-2.1l.1-.1s0-.1.1-.1c.2-.2.3-.4.5-.5.2-.1.4-.3.6-.4.1 0 .1 0 .1-.1.1 0 .1-.1.2-.1h.5c.1 0 .3-.1.5-.1h.3c.7 0 1.4.1 2.1.5.1.1.2.1.2.2 1.1.9 1.6 2.3 1.3 3.6.6-1.1 1.1-2.2 1.5-3.2.8-2.5.9-4.6.8-6.1v-.4h.6v.1c0 .1.1.2.1.3-.1 1.1.2 3.7-.8 7z"
        opacity="0.44"
        transform="translate(-93.45 -55)"
        vectorEffect="non-scaling-stroke"
      ></path>
    </g>
    <path
      fill="#FFF"
      d="M102 44.9h-.7c-1.4.1-3.9.5-6.6 2-.2.1-.3.2-.5.3-.2-.1-.3-.2-.5-.2.1-.1.3-.2.4-.3.1-.1.3-.2.4-.3.2-.1.3-.2.5-.3.3-.1.6-.3.8-.4.1-.1.3-.1.4-.2.2-.1.4-.2.6-.2.2-.1.4-.1.5-.2 1.7-.6 3.1-.7 3.8-.7h.7s.1.3.2.5z"
      opacity="0.6"
      transform="translate(148.2 187.84) translate(-97.85 -45.8)"
      vectorEffect="non-scaling-stroke"
    ></path>
    <path
      fill="#EC1C24"
      d="M0 3.1a3.1 3.1 0 116.2 0 3.1 3.1 0 01-6.2 0z"
      transform="translate(145.65 195.2) translate(-3.1 -3.1)"
      vectorEffect="non-scaling-stroke"
    ></path>
    <path
      fill="#390075"
      d="M98.4 52.8c0-.1 0-.2-.1-.3 0-.1 0-.2-.1-.2-.1-.4-.3-.7-.6-1-.1-.2-.3-.3-.5-.5-.5-.5-1.1-.8-1.8-.8h-.5c-.8.1-1.6.5-2.1 1.2 0 0 0 .1-.1.1-.5.7-.7 1.5-.6 2.3 0 .3.1.6.2.8.1.1.1.2.2.3.1.1.2.3.3.4.1.1.1.2.2.3l.3.3c.6.4 1.2.6 1.9.6.9 0 1.9-.4 2.5-1.2.4-.6.6-1.3.6-1.9.2-.2.2-.3.2-.4zm-4.9 2.8c-.1 0-.1 0 0 0-.1-.1-.3-.2-.3-.3-.2-.2-.3-.4-.4-.6 0-.1-.1-.1-.1-.2 0 0 0-.1-.1-.1-.1-.3-.2-.5-.2-.8-.1-.8.1-1.6.6-2.3.5-.6 1.2-1.1 2-1.2.5-.1 1 0 1.5.2.1.1.3.1.4.2h.1s.1 0 .1.1c.1 0 .1.1.2.1l.3.3.2.2.1.1c0 .1.1.1.1.2.2.3.3.5.4.8 0 .1.1.2.1.4.1.8-.1 1.6-.6 2.3-1.2 1.4-3.1 1.6-4.4.6z"
      transform="translate(145.6 195.19) translate(-95.25 -53.15)"
      vectorEffect="non-scaling-stroke"
    ></path>
    <path
      fill="#FFF"
      d="M97.7 51.2c-.1 0-.1-.1-.2-.1-1.4-1-3.3-.8-4.3.6-.9 1.2-.8 2.9.2 3.9-1.2-1.1-1.4-2.9-.4-4.2 1-1.4 3-1.6 4.3-.6.1.2.3.3.4.4z"
      opacity="0.6"
      transform="translate(145.38 194.92) translate(-95.03 -52.89)"
      vectorEffect="non-scaling-stroke"
    ></path>
    <path
      fill="#371A45"
      d="M97.7 55.1c-1 1.4-3 1.6-4.3.6-.4-.3-.7-.7-1-1.2.1.2.3.3.5.5 1.4 1 3.3.8 4.3-.6.7-.9.8-2.1.4-3.1.9 1 1 2.6.1 3.8z"
      opacity="0.35"
      transform="translate(145.71 195.85) translate(-95.36 -53.81)"
      vectorEffect="non-scaling-stroke"
    ></path>
    <path
      fill="#390075"
      d="M98.4 52.8c0-.1 0-.2-.1-.3 0-.1 0-.2-.1-.2-.1-.4-.3-.7-.6-1 .1.2.2.4.2.7.2.8 0 1.7-.6 2.4-.5.6-1.2 1.1-2 1.2-.8.1-1.6-.1-2.2-.6l-.2-.2-.3-.3c.1.1.1.2.2.3.1.1.2.3.3.4.1.1.1.2.2.3l.3.3c.6.4 1.2.6 1.9.6.9 0 1.9-.4 2.5-1.2.4-.6.6-1.3.6-1.9-.1-.3-.1-.4-.1-.5zm-4.9 2.8c-.1 0-.1 0 0 0-.1-.1-.3-.2-.3-.3-.2-.2-.3-.4-.4-.6l.2.2c.1.1.2.2.3.2.6.4 1.3.5 2 .4.8-.1 1.6-.6 2.1-1.2s.7-1.4.6-2.1c0-.2-.1-.5-.1-.7.2.3.3.5.4.8 0 .1.1.2.1.4.1.8-.1 1.6-.6 2.3-1.1 1.4-3 1.6-4.3.6z"
      opacity="0.35"
      transform="translate(145.85 195.89) translate(-95.5 -53.85)"
      vectorEffect="non-scaling-stroke"
    ></path>
    <path
      fill="currentColor"
      d="M98.6 52.7c-.1-.9-.6-1.6-1.2-2.2 0 0-.1 0-.1-.1-.7-.5-1.6-.8-2.5-.7-.9.1-1.7.6-2.2 1.3-.5.7-.8 1.5-.7 2.3v.1c.1.9.6 1.7 1.3 2.2 1.5 1.1 3.5.8 4.7-.7.6-.4.8-1.3.7-2.2zM93.1 55l-.1-.1c-.1-.2-.2-.4-.3-.5-.1-.2-.2-.5-.2-.7-.1-.8.1-1.5.6-2.1.5-.6 1.1-1 1.9-1.1.5-.1 1 0 1.5.2l.6.3c.4.3.6.6.8 1 0 .1.1.1.1.1s0 .1.1.1c.1.2.1.4.1.6.1.8-.1 1.5-.6 2.1-.9 1.2-2.7 1.5-3.9.6l-.3-.3c-.2-.1-.3-.1-.3-.2z"
      transform="translate(145.6 195.01) translate(-95.25 -52.97)"
      vectorEffect="non-scaling-stroke"
    ></path>
    <g transform="translate(145.65 195.14)">
      <linearGradient
        id="SVGID_12"
        x1="93.287"
        x2="97.226"
        y1="53.132"
        y2="53.132"
        gradientTransform="translate(-93.3 -51.1)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#EFC2EB"></stop>
        <stop offset="1" stopColor="#AFC1EE"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_12)"
        d="M0 2a2 2 0 114 0 2 2 0 01-4 0z"
        transform="translate(-2 -2)"
        vectorEffect="non-scaling-stroke"
      ></path>
    </g>
    <path
      fill="#FFF"
      d="M96.8 54.3c-.7.9-1.9 1-2.8.4-.2-.1-.3-.3-.5-.5h.1c.9.7 2.1.5 2.8-.4.5-.7.5-1.6.1-2.3.8.8 1 2 .3 2.8z"
      opacity="0.3"
      transform="translate(145.71 195.33) translate(-95.36 -53.29)"
      vectorEffect="non-scaling-stroke"
    ></path>
    <path
      fill="#390075"
      d="M97 52.2s0-.1 0 0c-.9-.7-2.2-.6-2.8.3-.5.7-.5 1.5-.1 2.2-.8-.7-.9-1.9-.3-2.7.7-.9 1.9-1 2.8-.4.1.2.3.4.4.6z"
      opacity="0.35"
      transform="translate(145.56 195) translate(-95.21 -52.96)"
      vectorEffect="non-scaling-stroke"
    ></path>
    <path
      fill="currentColor"
      d="M96.6 51.4c-.5-.4-1-.5-1.6-.4-.6.1-1.1.4-1.5.8-.7 1-.6 2.4.4 3.1.4.3.8.4 1.3.4.7 0 1.3-.3 1.8-.9.7-.9.6-2.3-.4-3zm-2.3 3.2c0-.1-.1-.1 0 0-.1-.1-.1-.1 0 0-.1-.1-.1-.1 0 0-.2-.1-.3-.3-.4-.4-.4-.6-.4-1.4.1-2 .3-.4.7-.6 1.1-.7.4-.1.9.1 1.3.3 0 0 .1 0 .1.1.2.1.3.3.4.5.3.6.3 1.3-.1 1.9-.7.6-1.8.8-2.5.3z"
      transform="translate(145.6 195.17) translate(-95.25 -53.13)"
      vectorEffect="non-scaling-stroke"
    ></path>
    <path
      fill="#FFF"
      d="M98.7 46.3c-.3-.4-1.3-.2-1.8.1-.5.3-.7.6-.6.8.2.3.6.2 1.1 0 .4-.2 1.6-.4 1.3-.9z"
      opacity="0.2"
      transform="translate(147.86 188.77) translate(-97.51 -46.73)"
      vectorEffect="non-scaling-stroke"
    ></path>
    <path
      fill="#FFF"
      d="M100.2 46.1c-.2-.3-.9-.4-1.1-.3-.3.1-.4.3-.2.5.2.3.5.3.7.2.2-.1.9 0 .6-.4z"
      opacity="0.2"
      transform="translate(149.88 188.2) translate(-99.53 -46.16)"
      vectorEffect="non-scaling-stroke"
    ></path>
    <path
      fill="#EC1C24"
      d="M88.7 63.8c-.9 1.2-2.8 1.7-2.8 1.7 0-.2 0-2 .9-3.1.8-1.1 2.3-1.4 2.6-1.4 0 0 .2 1.6-.7 2.8z"
      transform="translate(138.01 205.29) translate(-87.66 -63.25)"
      vectorEffect="non-scaling-stroke"
    ></path>
    <path
      fill="#FFF"
      d="M89.5 61v.3c-.5.1-1.7.4-2.4 1.4-.7.9-.8 2.2-.9 2.8-.2.1-.3.1-.3.1 0-.2 0-2 .9-3.1.9-1.2 2.4-1.5 2.7-1.5z"
      opacity="0.6"
      transform="translate(138.05 205.34) translate(-87.7 -63.3)"
      vectorEffect="non-scaling-stroke"
    ></path>
    <path
      fill="#390075"
      d="M88.7 63.8c-.9 1.2-2.8 1.7-2.8 1.7l3.4-4.5s.3 1.6-.6 2.8z"
      opacity="0.35"
      transform="translate(137.97 205.29) translate(-87.62 -63.25)"
      vectorEffect="non-scaling-stroke"
    ></path>
    <path
      fill="currentColor"
      d="M39.9 170.9c0 5.9-4.2 8.9-11.5 8.9H14.2v-33.1h12.9c7.5 0 11.6 2.9 11.6 9 0 3.3-1.3 5.7-3.8 7.2 3.1 1.3 5 4 5 8zm-19.1-18.5v8.2h6.6c1.2 0 2.3-.2 3.4-.8 1.1-.6 1.8-1.8 1.8-3.3 0-2.4-1.5-4-4.9-4h-6.9zm7.5 21.6c3.5 0 5-1.7 5-4.2 0-3.4-2.9-4.1-5.1-4.1h-7.4v8.3h7.5z"
      transform="translate(250.4 233.29) translate(-27.05 -163.25)"
      vectorEffect="non-scaling-stroke"
    ></path>
    <path
      fill="currentColor"
      d="M45 179.8v-33.1h6.6v33.1H45z"
      transform="translate(271.65 233.29) translate(-48.3 -163.25)"
      vectorEffect="non-scaling-stroke"
    ></path>
    <path
      fill="currentColor"
      d="M59.8 176.7c-2.4-2.4-3.6-5.5-3.6-9.3 0-3.8 1.2-6.9 3.6-9.3 2.4-2.4 5.5-3.6 9.3-3.6 3.8 0 6.9 1.2 9.3 3.6 2.4 2.4 3.6 5.5 3.6 9.3 0 3.8-1.2 6.9-3.6 9.3-2.4 2.4-5.5 3.6-9.3 3.6-3.8 0-6.9-1.2-9.3-3.6zm13.9-3.9c1.2-1.3 1.8-3.1 1.8-5.4 0-2.3-.6-4.1-1.8-5.4-1.2-1.3-2.8-1.9-4.7-1.9-1.9 0-3.4.6-4.6 1.9-1.2 1.3-1.9 3.1-1.9 5.4 0 2.3.6 4.1 1.9 5.4 1.2 1.3 2.8 1.9 4.7 1.9 1.9 0 3.4-.7 4.6-1.9z"
      transform="translate(292.45 237.44) translate(-69.1 -167.4)"
      vectorEffect="non-scaling-stroke"
    ></path>
    <path
      fill="currentColor"
      d="M103.4 155.1h6.6v24.6h-6.4v-2.6c-2.1 2.1-4.7 3.1-7.9 3.1-6.3 0-9.8-4.2-9.8-11.8V155h6.6v13.4c0 3.6 1.8 5.7 5 5.7 1.7 0 3.1-.6 4.2-1.7s1.6-2.9 1.6-5.1v-12.2z"
      transform="translate(321.3 237.64) translate(-97.95 -167.6)"
      vectorEffect="non-scaling-stroke"
    ></path>
    <path
      fill="currentColor"
      d="M139.1 157.9c2.2 2.2 3.5 5.6 3.5 9.5 0 3.9-1.3 7.2-3.5 9.4s-5.3 3.5-8.7 3.5c-3.2 0-5.8-1-7.8-3v11.2h-6.4V155h6.3v2.5c2.2-2.1 4.9-3.1 8.1-3.1 3.2 0 6.3 1.3 8.5 3.5zm-4.8 14.8c1.3-1.3 1.9-3 1.9-5.3s-.6-4.1-1.9-5.3c-1.3-1.3-2.9-1.9-4.8-1.9s-3.5.6-4.8 1.9c-1.3 1.3-1.9 3.1-1.9 5.4 0 2.3.6 4.1 1.9 5.4 1.3 1.3 2.8 1.9 4.8 1.9s3.5-.8 4.8-2.1z"
      transform="translate(352.75 241.49) translate(-129.4 -171.45)"
      vectorEffect="non-scaling-stroke"
    ></path>
    <path
      fill="currentColor"
      d="M170 157.9c2.2 2.2 3.5 5.6 3.5 9.5 0 3.9-1.3 7.2-3.5 9.4s-5.3 3.5-8.7 3.5c-3.2 0-5.8-1-7.8-3v11.2h-6.4V155h6.3v2.5c2.2-2.1 4.9-3.1 8.1-3.1 3.3 0 6.3 1.3 8.5 3.5zm-4.7 14.8c1.3-1.3 1.9-3 1.9-5.3s-.6-4.1-1.9-5.3c-1.3-1.3-2.9-1.9-4.8-1.9s-3.5.6-4.8 1.9c-1.3 1.3-1.9 3.1-1.9 5.4 0 2.3.6 4.1 1.9 5.4 1.3 1.3 2.8 1.9 4.8 1.9s3.5-.8 4.8-2.1z"
      transform="translate(383.65 241.49) translate(-160.3 -171.45)"
      vectorEffect="non-scaling-stroke"
    ></path>
    <path
      fill="currentColor"
      d="M193.9 155.1h7.2l-13.4 34.1h-6.4l3.9-9.7-10.4-24.4h7.3l6.1 16.2 5.7-16.2z"
      transform="translate(411.3 242.19) translate(-187.95 -172.15)"
      vectorEffect="non-scaling-stroke"
    ></path>
  </svg>
);
